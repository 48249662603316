import request from '@/api/request.js'

/**
 * @Description: 销售单
 */

// 分页查询
export function pageWmsSaleOrder(data) {
    return request({
        url: '/api/wmsSaleOrder/page',
        method: 'POST',
        data
    })
}

// 搜索过滤
export function listWmsSaleOrder(data) {
    return request({
        url: '/api/wmsSaleOrder/list',
        method: 'POST',
        data
    })
}

// 查看详情
export function detailWmsSaleOrder(data) {
    return request({
        url: '/api/wmsSaleOrder/detail',
        method: 'POST',
        data
    })
}

// 添加
export function addWmsSaleOrder(data) {
    return request({
        url: '/api/wmsSaleOrder/add',
        method: 'POST',
        data
    })
}

// 编辑
export function editWmsSaleOrder(data) {
    return request({
        url: '/api/wmsSaleOrder/edit',
        method: 'POST',
        data
    })
}

// 删除
export function delWmsSaleOrder(data) {
    return request({
        url: '/api/wmsSaleOrder/delete',
        method: 'POST',
        data
    })
}

// 批量删除
export function batchDeleteWmsSaleOrder(data) {
    return request({
        url: '/api/wmsSaleOrder/batchDelete',
        method: 'POST',
        data
    })
}

// 批量审核
export function batchConfirmWmsSaleOrder(data) {
    return request({
        url: '/api/wmsSaleOrder/batchConfirm',
        method: 'POST',
        data
    })
}

// 批量补货
export function batchReplenishmentWmsSaleOrder(data) {
    return request({
        url: '/api/wmsSaleOrder/batchReplenishment',
        method: 'POST',
        data
    })
}

