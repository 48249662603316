<template>
  <div>
    <div class="contents">
      <submenu curbg=0></submenu>
      <div class="tableSearch">
          <el-form :inline="true" ref="searchForm" :model="searchForm" label-width="90px">
                <el-form-item label="客户单号" style="margin-bottom:10px">

                  <el-input v-model="searchForm.erpId" clearable  style="width:190px"
                    placeholder="请输入客户单号"></el-input>
                </el-form-item>

                <el-form-item label="销售单号" style="margin-bottom:10px">

                  <el-input v-model="searchForm.code" clearable  style="width:190px"
                    placeholder="请输入销售单号"></el-input>
                </el-form-item>

                <el-form-item label="仓库" style="margin-bottom:10px">

                  <el-input v-model="searchForm.warehouseName" clearable  style="width:190px"
                    placeholder="请输入仓库"></el-input>
                </el-form-item>

                <el-form-item label="客户" style="margin-bottom:10px">

                  <el-input v-model="searchForm.customerName" clearable  style="width:190px"
                    placeholder="请输入客户"></el-input>
                </el-form-item>


                <span class="buttons">
                    <el-button @click="createSearch" type="primary" icon="el-icon-search" :loading="loading">查询</el-button>
                    <el-button @click="reset">重置</el-button>
                </span>
          </el-form>
      </div>

    <div class="mainbox">

     <vxe-toolbar ref="xToolbar" custom>
            <template #buttons>
              <!-- <el-button type="primary" class="tbBtn" icon="el-icon-plus" size="small" @click="handleAdd">添加</el-button> -->

              <el-dropdown type="primary" class="tbBtn" @command="handleCommand">
                      <el-button type="primary" plain >
                        批量操作<i class="el-icon-arrow-down el-icon--right"></i>
                      </el-button>

                      <el-dropdown-menu slot="dropdown" >
                        <el-dropdown-item command="batchConfirm">批量审核</el-dropdown-item>

                        <el-dropdown-item command="batchReplenishment">批量拿货</el-dropdown-item>

                        <el-dropdown-item command="batchDelete">批量取消</el-dropdown-item>

                      </el-dropdown-menu>

              </el-dropdown>              

            </template>
            <template #tools>
            </template>
      </vxe-toolbar>

      <vxe-table border
        id="table"
        empty-text="没有更多数据"
        header-align="center"
        stripe
        :data="tableData"
        :custom-config="{storage: true, resizable: true}"
        :column-config="{resizable: true}"
        :row-config="{isCurrent: true, isHover: true, keyField: 'id'}"
        size="small"
        @checkbox-all="handleSelectionChange"
        @checkbox-change="handleSelectionChange"
         ref="table">
          <vxe-column type="checkbox" width="40" fixed></vxe-column>


        <vxe-column
            title="单号"
            min-width="280"
        >
          <template slot-scope="scope">
            <p>销售单号：{{scope.row.code}}</p>
            <p>客户单号：{{scope.row.erpId}}</p>
                
          </template>
        </vxe-column>

        <vxe-column
            field="details"
            title="明细"
            min-width="500"
        >
          <template slot-scope="scope">
                <el-table
                :data="scope.row.details"
                size="mini"
                :show-header="false"
                >
                <el-table-column prop="skuName" label="商品名"></el-table-column>
                <el-table-column prop="skuSpec" label="规格"></el-table-column>
                <el-table-column prop="skuCode" label="商品编码" width="150"></el-table-column>
                <el-table-column prop="quantity" label="数量" width="50">
                    <template slot-scope="scope">
                          × <span>{{scope.row.quantity}}</span>
                    </template>                  
                </el-table-column>
                <el-table-column prop="unitName" label="单位"></el-table-column>
                </el-table>
          </template>
        </vxe-column>

        <vxe-column
            field="status"
            title="状态"
            min-width="80"
        >
          <template slot-scope="scope">
                {{enumMaps['WmsSaleOrderStatus'] && enumMaps['WmsSaleOrderStatus'][scope.row.status]}}
          </template>
        </vxe-column>

        <vxe-column
            field="consigneeId"
            title="收件人"
            min-width="300"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.consigneeId">
            <div>收件人：{{scope.row.consignee.name}} ({{scope.row.consignee.phone}})</div>
            <div>地址：{{scope.row.consignee.address}}</div>
            </div>
          </template>
        </vxe-column>

          <vxe-column
            field="warehouseName"
            title="仓库"
            min-width="100"
        >
          <template slot-scope="scope">
                {{scope.row.warehouseName}}
          </template>
        </vxe-column>

        <vxe-column
            field="customerName"
            title="客户"
            min-width="100"
        >
          <template slot-scope="scope">
            {{scope.row.customerName}}
          </template>
        </vxe-column>

        <vxe-column
            field="supplierName"
            title="供应商"
            min-width="100"
        >
          <template slot-scope="scope">
            {{scope.row.supplierName}}
          </template>
        </vxe-column>

        <vxe-column
            field="remark"
            title="备注"
            min-width="100"
        >
          <template slot-scope="scope">
                {{scope.row.remark}}
          </template>
        </vxe-column>

        <vxe-column
            field="completeTime"
            title="完成时间"
            min-width="160"
        >
          <template slot-scope="scope">
                {{scope.row.completeTime}}
          </template>
        </vxe-column>
        <vxe-column
            field="createTime"
            title="创建时间"
            min-width="160"
        >
          <template slot-scope="scope">
                {{scope.row.createTime}}
          </template>
        </vxe-column>

        <vxe-column title="操作" fixed="right" width="60">
            <template slot-scope="scope">
                  <el-button class="c-button" @click="handleDetail(scope.row)" type="text" size="small">详情</el-button>


             </template>
        </vxe-column>

      </vxe-table>

      <div class="paginations">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes"
            :page-sizes="[20, 50, 100, 200, 400]"
            @current-change="changePage" :current-page="page" :page-size="pageSize" @size-change="handleSizeChange"
            :total="total">
          </el-pagination>
      </div>
      </div>
    </div>

    <sale-add ref="wmsSaleOrderAdd" @success="getTopSearch"></sale-add>
    <sale-detail ref="wmsSaleOrderDetail" @success="getTopSearch"></sale-detail>
    <sale-edit ref="wmsSaleOrderEdit" @success="getTopSearch"></sale-edit>

  </div>
</template>

<script>
import Vue from 'vue'
import Clipboard from 'clipboard'
import submenu from "@/components/submenu"
import backImg from "@/assets/logofang.png"
import saleAdd from "./wmsSaleOrderAdd"
import saleDetail from "./wmsSaleOrderDetail"
import saleEdit from "./wmsSaleOrderEdit"

import { pageWmsSaleOrder, detailWmsSaleOrder, batchReplenishmentWmsSaleOrder, batchDeleteWmsSaleOrder, batchConfirmWmsSaleOrder } from "@/api/wms/wmsSaleOrder"
import { getEnums } from "@/api/common"
import { listWmsWarehouse } from "@/api/wms/wmsWarehouse"

import { listWmsCustomer } from "@/api/wms/wmsCustomer"

import { listWmsSupplier } from "@/api/wms/wmsSupplier"


let moment = require('moment')
Vue.prototype.Clipboard = Clipboard

export default {
  name: 'WmsSaleOrder',
  components: { submenu, saleAdd, saleDetail, saleEdit },
  data() {
    return {
      searchForm: {
      },
      total: 0,
      page: 1,
      counts: 0,
      pageSize: 20,
      tableData: [],
      backImg: backImg,
      enums: {
        WmsSaleOrderStatus: [],
      },
      enumMaps: {},
        optionsOfWmsWarehouse: [],

        optionsOfWmsCustomer: [],

        optionsOfWmsSupplier: [],

      loading: false,
      multipleSelection: [],
    }
  },
  methods: {
    initEnums() {
        const param = {
            names: Object.keys(this.enums)
        }
        getEnums(param).then(res=>{
            if(res.code===200) {
              this.enums = res.data.enums
              Object.keys(this.enums).forEach(key => {
                let map = {}
                this.enums[key].forEach(obj => map[obj.value] = obj.name)
                this.enumMaps[key] = map
              })
            } else {
                this.$message.error(res.message)
            }
        })
    },
    handleCommand(arg) {
        console.log("handleCommand", arg)
        this.multipleSelection = this.$refs.table.getCheckboxRecords()
        if(arg == "batchDelete") {
            return this.handleBatchDelete()
        } else if(arg == 'batchConfirm') {
            return this.handleBatchConfirm()
        } else if(arg == 'batchReplenishment') {
            return this.handleBatchReplenishment()
        }
    },
    handleBatchDelete() {
        console.log("handleBatchDelete", this.multipleSelection)
        if(this.multipleSelection.length ==0) {
            return this.$message.warning("请选择要取消的订单")
        }
        this.$confirm('确定要取消这'+ this.multipleSelection.length +'条订单吗，一旦执行不可恢复，请谨慎操作?', '温馨提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                 const param = {
                    ids: this.multipleSelection.map(it => it.id)
                 }
                 batchDeleteWmsSaleOrder(param).then(res => {
                    this.getTopSearch()
                    if(res.code===200) {
                        this.$message.success(res.message)
                    } else {
                        this.$message.error({message:res.message.replaceAll("\n", "<br/>"), dangerouslyUseHTMLString:true})
                    }
                 })
        }).catch(err=>{})
    },
    handleBatchConfirm() {
        if(this.multipleSelection.length ==0) {
            return this.$message.warning("请选择要审核的订单")
        }
        const param = {
          ids: this.multipleSelection.map(it => it.id)
        }
        batchConfirmWmsSaleOrder(param).then(res => {
          this.getTopSearch()
          if(res.code===200) {
              this.$message.success(res.message)
          } else {
              this.$message.error({message:res.message.replaceAll("\n", "<br/>"), dangerouslyUseHTMLString:true})
          }
        })
    },
    handleBatchReplenishment() {
        if(this.multipleSelection.length ==0) {
            return this.$message.warning("请选择要审核的订单")
        }
        const param = {
          ids: this.multipleSelection.map(it => it.id)
        }
        batchReplenishmentWmsSaleOrder(param).then(res => {
          this.getTopSearch()
          if(res.code===200) {
              this.$message.success(res.message)
          } else {
              this.$message.error({message:res.message.replaceAll("\n", "<br/>"), dangerouslyUseHTMLString:true})
          }
        })
    },
    handleAdd() {
      this.$refs.wmsSaleOrderAdd.onOpen()
    },
    handleDetail(row) {
      this.$refs.wmsSaleOrderDetail.onOpen(row)
    },
    handleEdit(row) {
      this.$refs.wmsSaleOrderEdit.onOpen(row)
    },
    handleSelectionChange(val) {
      console.log("handleSelectionChange", val)
      this.multipleSelection = val.records
    },
    del(row) {
      const param = {
          id: row.id
      }
      del(param).then(res=>{
          if(res.code===200) {
            this.$message.success(res.message)
            this.getTopSearch()
          } else {
            this.$message.error(res.message)
          }
      })
    },
    handleSizeChange(val){
      this.pageSize = val
      this.getTopSearch()
    },
    createSearch() {
      this.page = 1
      this.getTopSearch()
    },
    reset(){
      this.searchForm={
        date: ''
      }
      this.page = 1
      this.getTopSearch()
    },

    listWmsWarehouse(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsWarehouse(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsWarehouse = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    listWmsCustomer(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsCustomer(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsCustomer = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    listWmsSupplier(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsSupplier(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsSupplier = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    getTopSearch(){
      var that = this
      var param = that.searchForm
      param.pageNo = that.page
      param.pageSize = that.pageSize
      param.orderByCreated = "DESC"
      that.loading = true
      pageWmsSaleOrder(param).then(res => {
        that.loading = false
        if(res.code ===200) {
          var nodataArr = res.data.rows || []
          that.tableData = nodataArr
          that.total = res.data.totalRows
          that.counts = res.data.counts
        }else{
          this.$message.error(res.message)
        }
      }).catch(err=>{
        that.loading = false
      })
    },
    changePage (newPage) {
      var that = this
      if (that.page === newPage) {
        return
      }
      that.page = newPage
      that.getTopSearch()
    },
  },
  created() {
    this.initEnums()
    this.listWmsWarehouse()

    this.listWmsCustomer()

    this.listWmsSupplier()

    this.getTopSearch()
  },
  mounted(){
  }
}
</script>
<style scoped>
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #07C160;
  color: #FFF;
}
.el-tabs__nav-wrap::after{
  height: 1px;
}
.el-form-item__label{
  font-size: 12px;
}
.el-select {
    width: 190px;
}
.red {
  color: rgb(236, 78, 15);
}
.c-button{
  margin-left:8px;
}
.tbBtn {
  margin-right:10px;
}
.buttons {
}
</style>