<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners" @close="onClose"
    title="销售单详情"
    :visible.sync="visible"
    width="1000px"
    >

      <el-descriptions class="margin-top" :column="1"
      border>

            <el-descriptions-item label="来源单号">
            {{ row.erpId }}
            </el-descriptions-item>


            <el-descriptions-item label="销售单号">
            {{ row.code }}
            </el-descriptions-item>

            <el-descriptions-item label="开团易ID">
            {{ row.orderId }}
            </el-descriptions-item>

            <el-descriptions-item label="状态" v-if="enumMaps['WmsSaleOrderStatus']">
                 {{enumMaps['WmsSaleOrderStatus'][row.status]}}
            </el-descriptions-item>


            <el-descriptions-item label="仓库">
            {{ row.warehouseName }}
            </el-descriptions-item>


            <el-descriptions-item label="客户">
            {{ row.customerName }}
            </el-descriptions-item>


            <el-descriptions-item label="供应商">
            {{ row.supplierName }}
            </el-descriptions-item>

                <el-descriptions-item label="明细">
<el-table
        :data="row.details"
        ref="detailsTable"
        stripe
        border
>

        <el-table-column prop="skuName" label="商品" >
        </el-table-column>

        <el-table-column prop="skuSpec" label="规格" >
        </el-table-column>

        <el-table-column prop="skuCode" label="商品编码" >
        </el-table-column>

        <el-table-column prop="unitName" label="单位" >
        </el-table-column>

        <el-table-column prop="price" label="单价(元)" >
        </el-table-column>

        <el-table-column prop="quantity" label="数量" >
        </el-table-column>

        <el-table-column prop="totalPrice" label="总价(元)" >
        </el-table-column>

</el-table>              
</el-descriptions-item>

            <el-descriptions-item label="备注">
            {{ row.remark }}
            </el-descriptions-item>


            <el-descriptions-item label="创建人">
            {{ row.loginUserName }}
            </el-descriptions-item>

            <el-descriptions-item label="完成时间">
            {{ row.completeTime }}
            </el-descriptions-item>

            <el-descriptions-item label="流水号">
            {{ row.id }}
            </el-descriptions-item>

            <el-descriptions-item label="创建时间">
            {{ row.createTime }}
            </el-descriptions-item>




      </el-descriptions>


      <div slot="footer">
        <el-button @click="close">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getEnums } from "@/api/common"
import { detailWmsSaleOrder } from "@/api/wms/wmsSaleOrder"

export default {
  inheritAttrs: false,
  components: {},
  props: [],
  data() {
    return {
        visible: false,
        row: {
            id: '',
            erpId: '',
            model: '',
            code: '',
            status: '',
            warehouseId: '',
            warehouseName: '',
            customerId: '',
            customerName: '',
            supplierId: '',
            supplierName: '',
            details: '',
            remark: '',
            loginUserId: '',
            loginUserName: '',
            completeTime: '',
            createTime: '',
        },
        enums: {},
        enumMaps: {},
    }
  },
  computed: {},
  watch: {},
  created() {
    this.initEnums()
  },
  mounted() {},
  methods: {
      initEnums() {
          let names = []
          names.push('WmsSaleOrderStatus')
          const param = {
              names: names
          }
          getEnums(param).then(res=>{
              if(res.code===200) {
                this.enums = res.data.enums
                Object.keys(this.enums).forEach(key => {
                  let map = {}
                  this.enums[key].forEach(obj => map[obj.value] = obj.name)
                  this.enumMaps[key] = map
                })
              } else {
                  this.$message.error(res.message)
              }
          })
    },
    detail() {
        const param = {
            id: this.row.id
        }
        detailWmsSaleOrder(param).then(res=>{
          if(res.code === 200) {
            this.row = res.data
          } else {
            this.$message.error(res.message)
          }
        })
    },
    onOpen(row) {
        console.log("onOpen", row)
        this.visible = true
        this.row = row
        this.detail()
    },
    onClose() {
    },
    close() {
      this.visible = false
    },
  }
}

</script>
<style scoped>
.avatar {
    max-width: 200px;
    max-height: 200px
}
</style>
